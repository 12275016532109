<template>
  <div class="audit">
    <!-- 撤销报备-->
    <el-dialog title="项目审核" :visible.sync="cancelObj.isShow" :close-on-click-modal="false">
      <!-- element ui的表单 -->
      <el-form :model="cancel" :rules="cancelRules" ref="audit" label-width="100px" class="demo">
        <el-form-item label="审批意见" prop="radio" class="radio_box">
          <el-radio v-model="cancel.radio" label="1">时间太久没有更新情况</el-radio>
          <el-radio v-model="cancel.radio" label="2">已逾期</el-radio>
          <el-radio v-model="cancel.radio" label="3">已战败，客户已选其他产品</el-radio>
          <el-radio v-model="cancel.radio" label="4">其他</el-radio>
        </el-form-item>
        <el-form-item class="textArea">
          <el-input type="textarea" placeholder="请输入撤销报备的原因" v-model="cancel.marks" maxlength="200" show-word-limit rows="4"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="cancelObj.isShow = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { mapActions, mapGetters,mapState,mapMutations } from 'vuex';
export default {
  props:['cancelObj'],
  data() {
    return {
      
    };
  },
  computed: {
    ...mapState('projectFiling',['cancel','cancelRules'])
  },
  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style scoped>
.audit{
  font-family: 'SHSCN_Regular';
}
.audit /deep/ .el-dialog__title{
  font-family: 'SHSCN_Bold';
}
.audit /deep/ .el-form {
  display: flex !important;
  flex-direction: column;
}
.audit /deep/ .el-form-item__content{
  display: flex;
  flex-direction: column;
}
.audit /deep/ .el-form-item__label{
  font-family: 'SHSCN_Bold';
}
.audit /deep/ .el-form{
  margin: 0;
}
.demo /deep/ .el-form-item__content{
  margin:13px 0 0 114px !important;
}
/* 单选框的样式 */
.radio_box /deep/ .el-radio:nth-child(2){
  margin-top:2.78vh;
}
.radio_box /deep/ .el-radio:nth-child(3){
  margin-top:2.78vh;
}
.radio_box /deep/ .el-radio:nth-child(4){
  margin-top:2.78vh;
}
.textArea /deep/ .el-textarea__inner{
  resize: none;
}
.textArea /deep/ .el-input__count{
  right: 10px;
  top: 50px;
}
</style>