<template>
  <div class="audit">
    <!-- 报备延期 -->
    <el-dialog title="项目审核" :visible.sync="postponeObj.isShow" :close-on-click-modal="false">
      <!-- element ui的表单 -->
      <el-form :model="postpone" :rules="postponeRules" ref="postpone" label-width="80px"  class="demo">
        <el-form-item label="延长时间" prop="time">
          <el-select v-model="postpone.time" placeholder="请选择活动区域" style="width: 150%;">
            <el-option :label="item.value" :value="item.value" v-for="item in times" :key="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="textArea" label="备注说明" prop="marks">
          <el-input type="textarea" placeholder="请输入审批意见" v-model="postpone.marks" maxlength="200" show-word-limit rows="8" style="width: 188%;"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="postponeObj.isShow = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { mapActions, mapGetters,mapState,mapMutations } from 'vuex';
export default {
  props:['postponeObj'],
  data() {
    return {
      
    };
  },
  computed: {
    ...mapState('projectFiling',['times','postpone','postponeRules'])
  },
  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style scoped>
.audit{
  font-family: 'SHSCN_Regular';
}
.audit /deep/ .el-dialog__title{
  font-family: 'SHSCN_Bold';
}
.audit /deep/ .el-form {
  display: flex !important;
  flex-direction: column
}
.audit /deep/ .el-form-item__label{
  font-family: 'SHSCN_Regular';
  text-align: left;
}
.audit /deep/ .el-form{
  margin: 0 0 0 3vw;
}
.demo /deep/ .el-form-item{
  display: flex;
}
.demo /deep/ .el-form-item__content{
  margin:0.5vh 0 0 1vw !important;
}
.textArea /deep/ .el-textarea__inner{
  /* width: 202% !important; */
  resize: none;
}
.textArea /deep/ .el-input__count{
  right: 10px;
}

</style>