<template>
  <div class="audit">
    <!-- 项目审核 -->
    <el-dialog title="项目审核" :visible.sync="auditObj.isShow" :close-on-click-modal="false">
      <!-- element ui的表单 -->
      <el-form :model="audit" :rules="auditRules" ref="audit" label-width="100px" class="demo">
        <el-form-item label="审批意见" prop="radio">
          <el-radio v-model="audit.radio" label="1">同意</el-radio>
          <el-radio v-model="audit.radio" label="2">不同意,驳回</el-radio>
        </el-form-item>
        <el-form-item class="textArea">
          <el-input type="textarea" placeholder="请输入审批意见" v-model="audit.text" maxlength="200" show-word-limit rows="8"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="auditObj.isShow = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { mapActions, mapGetters,mapState,mapMutations } from 'vuex';
export default {
  props:['auditObj'],
  data() {
    return {
      
    };
  },
  computed: {
    ...mapState('projectFiling',['audit','auditRules'])
  },
  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style scoped>
.audit{
  font-family: 'SHSCN_Regular';
}
.audit /deep/ .el-dialog__title{
  font-family: 'SHSCN_Bold';
}
.audit /deep/ .el-form {
  display: flex !important;
  flex-direction: column
}
.audit /deep/ .el-form-item__content{
  display: flex;
  flex-direction: row;
}
.audit /deep/ .el-form-item__label{
  font-family: 'SHSCN_Bold';
  text-align: left;
}
.audit /deep/ .el-form{
  margin: 0;
}
.demo /deep/ .el-form-item{
  display: flex;
  flex-direction: column;
}
.demo /deep/ .el-form-item__content{
  margin:0.5vh 0 0 1vw !important;
}
.textArea /deep/ .el-textarea__inner{
  resize: none;
}
.textArea /deep/ .el-input__count{
  right: 10px;
  
}
</style>