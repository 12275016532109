<template>
  <!-- 报备详情 -->
  <div class="purchase_detailed">
    <!-- 板块中心区域 -->
    <div class="purchase_mid">
      <div class="purchase_header">
        <!-- 标题 -->
        <div class="purchase_title">
          <p>编号:{{inform.number}}</p>
          <div class="gobackImg">
            <img src="../../../assets/image/user_detail/group_140.png" alt="" @click="goback">
          </div>
        </div>
        <div class="agentDetail_content">
          <p>代理商<span>{{inform.agent}}</span></p>
          <p>客户信息<span>{{inform.customerName}}</span></p>
          <p>代理负责人<span>{{inform.leader}}</span></p>
          <p>联系电话<span>13800001252</span></p>
          <p>申请时间<span>{{inform.applicationTime}}</span></p>
          <p>报备状态
            <span v-if="inform.filingStatus == '0'">
              <img src="../../../assets/image/purchaseManage_slices/eliptical_40.png" alt="" style="margin-right:5px;">已审核
            </span>
            <span v-else-if="inform.filingStatus == '1'">
              <img src="../../../assets/image/purchaseManage_slices/eliptical_38.png" alt="" style="margin-right:5px;">待审核
            </span>
            <span v-else-if="inform.filingStatus == '2'">
              <img src="../../../assets/image/purchaseManage_slices/elliptical_50.png" alt="" style="margin:0 5px 0 10px;">报备到期
            </span>
            <span v-else-if="inform.filingStatus == '3'">
              <img src="../../../assets/image/purchaseManage_slices/elliptical_50.png" alt="" style="margin:0 5px 0 10px;">报备失效
            </span>
            
          </p>
          <p>所属地区<span>江苏省南通市</span></p>
          <p  class="mapFont">详细地址<span>江苏省南通市通州区新世纪大道998号</span></p>
        </div>
      </div>
      <!-- 表格区域 -->
    <div class="purchase_form">
      <!-- 项目详细 -->
      <div class="order_title">
        <h4>项目详细</h4>
        <div class="order_btn">
          <el-button style="background-color:#fff;color:#171717" @click="postpone" id="postpones">报备延期</el-button>
          <el-button style="background-color:#fff;color:#171717" @click="cancell" id="cancells">撤销报备</el-button>
          <el-button style="background-color:#0666F5;color:#fff" @click="audit" id="audits">审核</el-button>
        </div>
      </div>
      <!-- 基本信息--表单 -->
      <div class="basic_inform">
          <div class="inform_title">
            <img src="../../../assets/image/EquipmentStorage_slices/rectangle_31.png" alt="" width="3px" height="16px">
            <h4>基本信息</h4>
          </div>
          <el-form ref="inform" :model="inform" label-width="80px">
            <!-- 所属代理商 -->
              <el-form-item label="所属代理商" class="level">
                <el-input v-model="inform.agent" :disabled="true" style="width:95%"></el-input>
              </el-form-item>
              <!-- 代理商等级 -->
              <el-form-item label="代理商等级" class="level">
                <el-input v-model="inform.agentLevel" :disabled="true" style="width:95%"></el-input>
              </el-form-item>
              <!-- 项目负责人 -->
              <el-form-item label="项目负责人" class="level">
                <el-input v-model="inform.leader" :disabled="true" style="width:95%"></el-input>
              </el-form-item>
              <!-- 联系方式 -->
              <el-form-item label="联系方式">
                <el-input v-model="inform.phone" :disabled="true" style="width:95%"></el-input>
              </el-form-item>
              <!-- 项目名称 -->
              <el-form-item label="项目名称" class="level">
                <el-input v-model="inform.projectName" :disabled="true" style="width:95%"></el-input>
              </el-form-item>
              <!-- 项目预估金额 -->
              <el-form-item label="项目预估金额" class="money">
                <el-input v-model="inform.expectSum" :disabled="true" style="width:95%"></el-input>
              </el-form-item>
              <!-- 预计开始时间 -->
              <el-form-item label="预计开始时间" class="money">
                <el-input v-model="inform.beginTime" :disabled="true" style="width:95%"></el-input>
              </el-form-item>
              <!-- 空 占位 -->
              <el-form-item ></el-form-item>
              <!-- 项目介绍 -->
              <el-form-item label="项目介绍" class="textArea">
                <el-input type="textarea" v-model="inform.projectIntroduce" :disabled="true" rows="4" ></el-input>
              </el-form-item>
              <!-- 空 占位 -->
              <el-form-item ></el-form-item>
              <!-- 空 占位 -->
              <el-form-item ></el-form-item>
              <!-- 空 占位 -->
              <el-form-item ></el-form-item>
              <!-- 附件信息 -->
              <el-form-item label="附件信息" class="information">
                <img src="" alt="">
              </el-form-item>
          </el-form>
      </div>
      <!-- 报备期限 -->
      <div class="filing_time">
        <div class="filing_title">
          <img src="../../../assets/image/EquipmentStorage_slices/rectangle_31.png" alt="" width="3px" height="16px">
          <h4>报备期限</h4>
        </div>
        <el-form ref="inform" :model="inform" label-width="80px">
          <!-- 报备申请周期 -->
          <el-form-item label="报备申请周期" class="money">
            <el-input v-model="inform.agent" :disabled="true"></el-input>
          </el-form-item>
          <!-- 报备到期 -->
          <el-form-item label="报备到期">
            <el-input v-model="inform.agent" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="项目介绍" class="introduction">
            <el-input type="textarea" v-model="inform.projectIntroduce" :disabled="true" rows="2" ></el-input>
          </el-form-item>
        </el-form>
        <!-- 审核 -->
        <audit :auditObj="auditObj"></audit>
        <!-- 撤销报备 -->
        <cancel :cancelObj="cancelObj"></cancel>
        <postpone :postponeObj="postponeObj"></postpone>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapActions, mapGetters,mapState,mapMutations } from 'vuex';
// 引入审核
import audit from "./audit.vue";
// 引入撤销报备
import cancel from "./cancel.vue";
// 引入报备延期
import postpone from "./postpone.vue";
export default {
  components: {
    audit,//审核
    cancel,//撤销报备
    postpone,//报备延期
  },
  data () {
    return {
      inform:{
        agentLevel:'一级代理商',//代理商等级
        phone:13800001252,//联系方式
        expectSum:'100万',//项目预估金额
        beginTime:'2023-03-30',//预计开始时间
        projectIntroduce:'一级代理商',//项目介绍
      },
      auditObj:{ //审核
        isShow:false,
      },
      cancelObj:{ //
        isShow:false,
      },
      postponeObj:{
        isShow:false,
      }
    };
  },
  computed: {
    ...mapState('projectFiling',['form'])
  },
  mounted () {
    let informs = JSON.parse(sessionStorage.getItem('informs'));
    this.inform = {...this.inform,...informs};

    console.log(this.inform);
  },

  methods: {
    goback(){
      this.$router.go(-1)
    },
    // 审核
    audit(){
      let auditsBtn = document.querySelector('#audits');//审核按钮
      let cancellBtn = document.querySelector('#cancells');//撤销报备
      let postponeBtn = document.querySelector('#postpones');//报备延期
      auditsBtn.style.backgroundColor = "#0666F5";
      auditsBtn.style.color = "#fff"
      cancellBtn.style.backgroundColor = "#fff";
      cancellBtn.style.color = '#171717'
      postponeBtn.style.backgroundColor = "#fff";
      postponeBtn.style.color = '#171717'
      this.auditObj.isShow = true;
    },
    cancell(){
      let auditsBtn = document.querySelector('#audits');
      let cancellBtn = document.querySelector('#cancells');
      let postponeBtn = document.querySelector('#postpones');
      auditsBtn.style.backgroundColor = "#fff";
      auditsBtn.style.color = '#171717'
      cancellBtn.style.backgroundColor = "#0666F5";
      cancellBtn.style.color = "#fff"
      postponeBtn.style.backgroundColor = "#fff";
      postponeBtn.style.color = "#171717"
      this.cancelObj.isShow = true
    },
    // 延期
    postpone(){
      let auditsBtn = document.querySelector('#audits');
      let cancellBtn = document.querySelector('#cancells');
      let postponeBtn = document.querySelector('#postpones');
      auditsBtn.style.backgroundColor = "#fff";
      auditsBtn.style.color = "#171717"
      cancellBtn.style.backgroundColor = "#fff";
      cancellBtn.style.color = '#171717'
      postponeBtn.style.backgroundColor = "#0666F5";
      postponeBtn.style.color = "#fff"
      this.postponeObj.isShow = true;
    }

  },
};
</script>

<style  scoped>
.purchase_detailed .purchase_mid {
  width: 82.5vw;
  height: 85vh;
  /* overflow-y: scroll; */
  font-family: 'SHSCN_Regular';
}

/* 头部--开始 */
.purchase_mid .purchase_header {
  width: 100%;
  height: 176px;
  background-color: #fff;
}
.purchase_title{
  height: 25%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5vh 1.5% 0 1.5%;
}
.purchase_title > p{
  font-family: "SHSCN_Bold";
  font-size: 18px;
}
.agentDetail_content{
  height: calc(100% - 35%);
  padding: 0 0.5% 0 1.5%;
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  align-items: center;
}
.agentDetail_content p{
  font-family: "SHSCN_Regular";
  font-size: 14px;
  color:#ABABAB; 
}
.agentDetail_content span{
  color: #171717;
  margin: 0 0 0 4%;
}
/* 头部详细地址 */
.mapFont{
  width: 170%;
}
/* 表单区域 */
.purchase_mid .purchase_form {
  width: 100%;
  background-color: #fff;
  margin-top: 2vh;
  /* overflow-y: scroll; */
}
/* 订单详情 */
.order_title{
  display: flex;
  justify-content: space-between;
}
.order_title>h4{
  font-size: 18px;
  font-family: 'SHSCN_Bold';
  margin: 1.46vw 0 0 1.46vw;
}
.order_title>.order_btn{
  margin: 1.46vw 1.46vw 0 0;
}
/* button按钮的大小 */
.order_btn /deep/ .el-button {
  width: 78px;
  height: 32px;
  padding: 0;
}
/* 基本信息--开始 */
/* 基本信息标题 */
.inform_title{
  display: flex;
  margin-top: 1.5vh;
}
.inform_title img{
  margin: 0 0.42vw 0 1.88vw;
}
.inform_title h5{
  font-family: 'SHSCN_Regular';
  color: #171717;
}
/* 表单区域 */
.basic_inform .el-form{
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}
::v-deep .el-form{
  margin: 1.5vh 0 0 2.47vw;
  font-family: 'SHSCN_Regular';
}
.el-form /deep/ .el-form-item__label{
  font-size: 12px;
}
/* .el-form /deep/ .money{
  position: relative;
  left: 3%;
} */
.money /deep/ .el-form-item__label{
  width: 84px !important;
}
.money /deep/ .el-form-item__content{
  margin-left: 84px !important;
}
/* 文本域样式 */
.textArea{
  display: flex;
  flex-direction: column;
}
.textArea /deep/ .el-textarea__inner {
  resize: none;
  width: 330%;
  margin-left: -4vw;
}
.information{
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
}
.information img{
  width: 80px;
  height: 40px;
  background-color: #ABABAB;
}
/* 报备期限 */
.filing_title{
  display: flex;
}
.filing_title img{
  margin: 0 0.42vw 0 1.88vw;
}
.filing_title h5{
  font-family: 'SHSCN_Regular';
  color: #171717;
}
.filing_time /deep/ .el-form{
  display: grid;
  grid-template-columns: 25% 25%;
}
.introduction /deep/ .el-textarea__inner {
  resize: none;
  width: 310%;
}


/* 多媒体查询 */
/* 小于1920 */
@media screen and (max-width: 1920px){

}
/* 小于1200px */
@media screen and (max-width: 1200px) {
.agentDetail_content  p{
  font-size: 12px;
  transform: scale(0.7);
}
.agentDetail_content  span{
  font-size: 12px;
  transform: scale(0.7);
}
}
</style>